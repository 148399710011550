@import './styles.scss';

html {
	--ion-font-family: 'メイリオ', 'Hiragino Kaku Gothic Pro', 'Meiryo', 'ヒラギノ角ゴ Pro W3', 'MS PGothic', 'MS UI Gothic', Helvetica, Arial, sans-serif;
}

//////////////////////////////////////////////////////////////////////
// アプリスタイル
// 全体
.div-app {
	width: 100%;
	height: 100%;
}

// ヘッダーメニュー
.div-app-header {
	background-color: #f8f8f8;
	--ion-background-color: #f8f8f8;
	border-bottom: 1px solid #e7e7e7;
}

// グローバル（サイド）メニュー部分
.div-app-main-side-globalmenu {
	height: 100%;
	width: 100px; // 幅は仮置
	// ボーダーをHTMLに直接記載している
}

// サイドグローバルメニューとフラグメントを含んだブロック
.div-app-main {
	height: calc(100% - 64px);
	// width: 100%;

	// グローバルサイドメニューとフラグメント部分を左右に配置
	// グローバルサイドメニューを固定にして、フラグメント部分を可変幅にしてる
	display: flex;
	flex-direction: column;
	overflow: auto;
}

@media (max-width: 599px) {
	// サイドグローバルメニューとフラグメントを含んだブロック
	.div-app-main {
		height: calc(100% - 56px);
		// width: 100%;

		// グローバルサイドメニューとフラグメント部分を左右に配置
		// グローバルサイドメニューを固定にして、フラグメント部分を可変幅にしてる
		display: flex;
		flex-direction: column;
		overflow: auto;
	}
}

// 各メインフラグメント
.div-app-main-display {
	// flex: auto;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	overflow: hidden;
}

//////////////////////////////////////////////////////////////////////
// フラグメント用スタイル
// フラグメントの中身部分
.div-fragment-main {
	width: 100%;
	height: 100%;
}
.div-fragment-main-panel,
.div-fragment-main-panel-container {
	width: 100%;
	height: 100%;
	overflow: auto;
}


// フラグメント領域のアクションエリア
.div-fragment-main-panel-action {
	background-color: #fff;
	margin: 10px;
	padding: 5px 23px 5px 23px;
	border-bottom: 1px solid #eee;
	border-top: 1px solid #eee;
	button {
		height: 30px;
		line-height: 3px;
		margin-left: 5px;
	}
	.mat-button-wrapper {
		font-size: 8px;
	}
}

//////////////////////////////////////////////////////////////////////
// パネル用スタイル
// パネルのアクションエリア(サーベイ管理のみ)
.div-panel-main-action {
	background-color: #fff;
	height: 40px;
	margin-top: -30px;
	margin-bottom: 30px;
	padding: 5px 23px 5px 23px;
	border-bottom: 1px solid #eee;
	border-top: 1px solid #eee;
}

// パネルのアクションエリア(サーベイ管理のみ)
.div-panel-main-action div {
	position: relative;
	float: left;
}

// パネルのアクションエリアのボタン(サーベイ管理)
.div-panel-main-action-left {
	width: 80%;
	button {
		height: 30px;
		line-height: 3px;
	}
	.mat-button-wrapper {
		font-size: 8px;
	}
}

// パネルの検索エリア
.div-panel-main-action-label-contents {
	display: grid;
	grid-template-columns: 150px 1fr;
}
.div-panel-main-action-label-contents.contents-input
{
	display: grid;
	grid-template-columns: 150px 1fr;
	align-items: center;
}

.div-panel-main-action-right {
	width: 20%;
	button {
		height: 30px;
		line-height: 3px;
	}
	.mat-button-wrapper {
		font-size: 8px;
	}
}

// 登録画面の日付ピッカー用
input.schedule-input-area {
	width: 200px;
	height: 25px;
}

//////////////////////////////////////////////////////////////////////
// パネルの詳細用スタイル
// パネルのアクションエリア(サーベイ管理のみ)
.div-panel-detail-action {
	background-color: #fff;
	height: 80px;
	margin-top: 10px;
	margin-bottom: 30px;
	padding: 20px 23px 20px 23px;
	border-bottom: 1px solid #eee;
	border-top: 1px solid #eee;
}

// パネルのアクションエリア(サーベイ管理のみ)
.div-panel-detail-action div {
	position: relative;
	float: left;
}

//////////////////////////////////////////////////////////////////////
// パーツ用 共通スタイル

///////////////////////////////////
// スライドアニメーションするブロックの親用 親がこれでないとだめ
.slide-animation-parent {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}
// スライドアニメーションするブロック用
.slide-animation {
	position: absolute; // relativeの上に、absoluteのブロックが来ると重なる。
	top: 0px;
	z-index: 500; //Mat系のラベルで100のヤツがいるので大きくしておく。でないとパネルが上に乗らない
	width: 100%;
	height: 100%;

	display: table; // これをいれないと、中身のテーブルとかがはみ出るが、.mat-cardがdisplay:blockに、どこからか上書きされてて、これ変えられれば、これいらないとおもわれる

	// 背景をいれないと下が透けてしまう
	background-color: #fff;

	// // 検証用
	// border: 1px solid black;
}



///////////////////////////////////
// Matテーブル用
.mat-table {
	width: 100%; // これが無いと、テーブルとページ制御の部分がずれる
	height: 100%;
}

// 操作用列
.mat-cell-ctrl-col {
	width: 100px;
}
// Matテーブル内のボタン用
.row-button-list {
	width: 100%;
	transition: 0.3s;
	font-size: 12px;
	.mat-button-wrapper {
		text-align: left;
	}
}

// Matテーブル内のボタン用
.row-button-list-action {
	//   width: 50%;
	height: 35px;
	//   transition: 0.3s;
	font-size: 11px;
}

.row-button-list:after {
	position: absolute;
	bottom: 0;
	left: 50%;
	content: '';
	width: 0;
	height: 2px;
	background-color: #fff;
	transition: 0.3s;
	transform: translateX(-50%);
}
.row-button-list:hover:after {
	width: 100%;
}

// テーブル
.th.mat-header-cell {
	background-color: #fff;
	color: #ddd;
	font-size: 14px;
	text-align: center;
}
th.mat-header-cell {
	text-align: center;
	// color: #3484be;
	font-weight: bold;
	// background-color: #ddd;
}

td.mat-cell {
	font-size: 12px;
}

///////////////////////////////////
// 汎用ボタン
// ボタン
.button-right {
	float: right;
	margin-bottom: 10px;
}

////////////////////////////////////////////////////////
// ポップアップ
.mat-dialog-title {
	background-color: #f8f8f8;
	height: 40px;
}
.dialog-header {
	display: flex;
	background: rgba(255, 255, 255, 0);
	color: black;
}
.example-spacer {
	flex: 1 1 auto;
}


.input_required {
	color: #3484be;
	font-size: 9px;
  }


@media print {
	.mat-toolbar,
	.slide-panel-header,
	.div-fragment-main-panel-action {
		display: none !important;
	}
}
